import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { ArticleTag } from 'app/models/articleTag.model';


@Injectable()
export class GlobalService {

    bleuRefletModule: any;

    constructor(
        private _apiService: ApiService
    ) {}

    setBEModule(val: any): void{
        this.bleuRefletModule = val;
    }
  
    getBEModule(): any{
        return this.bleuRefletModule;
    }

    // public async getBackendInformation(): Promise<void> {
    //     await this.setArticleTagsList();
    //     return;
    // }

    // public getArticleTagsList(): any {
    //     return JSON.parse(localStorage.getItem('articleTags'));
    // }



    // public getArticleTagName(id: number): any {
    //     const articleTags = this.getArticleTagsList();
    //     const articleTag = articleTags.filter(
    //         (data) => {
    //             return data.id === id;
    //         }
    //     );
    //     return articleTag[0].name;
    // }

    // private setArticleTagsList(): Promise<string>
    // {
    //     return new Promise((resolve, reject) => {
    //         this._apiService.getArticleTags()
    //         .subscribe((response: any) => {
    //             localStorage.setItem('articleTags', JSON.stringify(response));
    //             resolve(response);
    //         }, reject);
    //     });
    // }
}
