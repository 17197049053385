import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class SessionService {

    public logged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public expirationDate: number;

    constructor() {}

    public setTokens(token, refreshToken): any {
        this.setToken(token);
        this.setRefreshToken(refreshToken);
        return true;
    }

    public getToken(): any {
        return localStorage.getItem('BleuRefletToken');
    }

    public clearTokens(): any {
        localStorage.removeItem('BleuRefletToken');
        localStorage.removeItem('BleuRefletRefreshToken');
    }

    public getRefreshToken(): any {
        return localStorage.getItem('BleuRefletRefreshToken');
    }

    public isLogged(): boolean {
        return this.getToken() != null;
    }

    private setToken(token: string): any {
        localStorage.setItem('BleuRefletToken', token);
    }

    private setRefreshToken(refreshToken: string): any {
        localStorage.setItem('BleuRefletRefreshToken', refreshToken);
    }
}
