import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { SessionService } from './session.service';


@Injectable()
export class UserService {
    public expirationDate: number;
    public userInfos: User;

    constructor(
        private _appConfig: AppConfig,
        private _sessionService: SessionService,
        private _apiService: ApiService,
        private _http: HttpClient
    ) {
        this.setUser().subscribe();
    }

    public setUser(): any {
        const token = this._sessionService.getToken();
        if (token) {
            const jwtDecoded = jwt_decode(token);
            this.expirationDate = jwtDecoded.exp;

            return this._apiService.getUserInfos().pipe(
                map(
                    (userInfos: any) => {
                        this.userInfos = userInfos;
                        return userInfos;
                    },
                    (err: any) => {
                        if (err.status === 401) {
                            console.log(err);
                            this._sessionService.clearTokens();
                        }
                    }
                )
            );
        } else {
            return of(null);
        }
    }
}
