import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    
    // {
    //     id       : 'dashboards',
    //     title    : 'Dashboards',
    //     translate: 'NAV.DASHBOARDS',
    //     type     : 'item',
    //     icon     : 'dashboard',
    //     url      : '/apps/dashboards/analytics'
    // },
    {
        id       : 'collections',
        title    : 'Collections',
        translate: 'NAV.COLLECTIONS',
        type     : 'item',
        icon     : 'apps',
        url      : '/apps/collections'
    },
    {
        id       : 'articles',
        title    : 'Articles',
        translate: 'NAV.ARTICLES',
        type     : 'item',
        icon     : 'collections',
        url      : '/apps/articles'
    },
    {
        id       : 'models',
        title    : 'Modèles',
        translate: 'NAV.MODELS',
        type     : 'item',
        icon     : 'account_box',
        url      : '/apps/models'
    },
    {
        id       : 'statistics',
        title    : 'Statitiques',
        translate: 'NAV.STATISTICS',
        type     : 'item',
        icon     : 'signal_cellular_alt',
        url      : 'apps/statistics'
    },
    // {
    //     id       : 'invoices',
    //     title    : 'Factures',
    //     translate: 'NAV.INVOICES',
    //     type     : 'item',
    //     icon     : 'picture_as_pdf',
    //     url      : 'apps/invoices'
    // },
    // {
    //     id       : 'offers',
    //     title    : 'Offres',
    //     translate: 'NAV.OFFERS',
    //     type     : 'item',
    //     icon     : 'devices',
    //     url      : 'apps/offers'
    // },
    // {
    //     id       : 'admin',
    //     title    : 'Admin',
    //     translate: 'NAV.ADMIN',
    //     type     : 'group',
    //     icon     : 'apps',
    //     children : [
    //         {
    //             id       : 'users',
    //             title    : 'Users',
    //             translate: 'NAV.USERS',
    //             type     : 'item',
    //             icon     : 'supervised_user_circle',
    //             url      : '/apps/articles'
    //         }

    //     ]
    // }
  
    
];
