export const locale = {
    lang: 'fr',
    data: {
        NAV: {
            ADMIN: 'Administration',
            APPLICATIONS: 'Applications',
            ARTICLES: 'Articles',
            ARTICLETAGS: 'Tags de l\'article',
            DASHBOARDS  : 'Tableau de bord',
            COLLECTIONS : 'Collections',
            MODELS      : 'Modèles',
            STATISTICS  : 'Statistiques',
            INVOICES    : 'Factures',
            OFFERS      : 'Offres',
            CALENDAR    : 'Calendar',
            ECOMMERCE   : 'E-Commerce',
            ACADEMY     : 'Academy',
            MAIL        : {
                TITLE: 'Mail',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Mail Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contacts',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard',
            USERS : 'Utilisateurs'
        }
    }
};
