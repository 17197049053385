//
// ─── IMPORTS ────────────────────────────────────────────────────────────────────
//

import { AppConfig } from './app.config';
import { Config } from './config';
// import { KeycloakService } from 'keycloak-angular';

//
// ─── FUNCTIONS ────────────────────────────────────────────────────────
//

export function initConfig(config: AppConfig): Promise<Config> {
  return config.load();
}

// function initKeycloak(
//   appConfig: AppConfig,
//   keycloak: KeycloakService
// ): () => Promise<any> {
//   return (): Promise<any> => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const _keycloakConfig = {
//           url: appConfig.getConfig().keycloakUrl + '/auth',
//           realm: appConfig.getConfig().keycloakRealm,
//           clientId: appConfig.getConfig().keycloakClientId,
//           clientSecret: appConfig.getConfig().keycloakClientSecret
//         };
//         await keycloak.init({
//           config: _keycloakConfig,
//           initOptions: {
//             onLoad: 'login-required',
//             checkLoginIframe: false
//           },
//           bearerExcludedUrls: []
//         });
//         resolve();
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };
// }

export function initializer(
  appConfig: AppConfig,
  // keycloak: KeycloakService
): () => Promise<any> {
  return (): Promise<any> => {
    // return initConfig(appConfig).then(initKeycloak(appConfig, keycloak));
    return initConfig(appConfig);
  };
}
