import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class LoginService {

    constructor(
        private appConfig: AppConfig,
        private _http: HttpClient
    ) {
    }

    private _loginUrl = `${this.appConfig.getConfig().backendUrl}/login`;


    public login(username: string, password: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const body = new URLSearchParams();
        body.set('login', username);
        body.set('password', password);
        return this._http.post(this._loginUrl, body.toString(), httpOptions);
    }
}
