import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { SessionService } from './session.service';

/**
 * Models
 */
import { Article } from '../models/article.model';
import { Model } from '../models/model.model';
import { User } from '../models/user';

@Injectable()
export class ApiService {
    public urlBleuRefletBackend: string;
    private _token: string;
    private _httpOptions: any;
    public userInfos: any;
    
    constructor(
        private _appConfig: AppConfig,
        private _sessionService: SessionService,
        private _http: HttpClient
    ) {
        this.urlBleuRefletBackend = this._appConfig.getConfig().backendUrl;
        this.setOptions();
    }

    /**
     * USERS
     */

    public getUserInfos(): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/user/informations`, this._httpOptions);
    }

    public addUserFiligrane(data: any): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/user/filigrane`, data, this._httpOptions);
    }

    public addUserLogo(data: any): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/user/logo`, data, this._httpOptions);
    }

    public modifyModuleSettings(data: any): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/user/modulesettings`, data, this._httpOptions);
    }

    /**
     * COLLECTIONS
     */
    public getUserCollections(): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/user/collectionbasic`, this._httpOptions);
    }
    public getCollection(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/collectionbasic/${id}`, this._httpOptions);
    }
    public purgeCollectionCache(): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/purgecollectioncache/`, this._httpOptions);
    }

    public createCollection(data: Article): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/collection`, data, this._httpOptions);
    }

    public modifyCollection(data: Article, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/collection/${id}`, data, this._httpOptions);
    }

    public deleteCollection(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/collection/${id}`, this._httpOptions);
    }

    /**
     * ARTICLES
     */
    public getUserArticles(): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/user/article/`, this._httpOptions);
    }
    
    public getArticle(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/article/${id}`, this._httpOptions);
    }

    public createArticle(data: Article): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/article`, data, this._httpOptions);
    }

    public createArticleFromZipFile(file: File): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/articlezip`, file, this._httpOptions);
    }

    public modifyArticle(data: Article, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/article/${id}`, data, this._httpOptions);
    }

    public calibrateArticle(data: any, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/article/${id}/calibrate`, data, this._httpOptions);
    }

    public deleteArticle(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/article/${id}`, this._httpOptions);
    }

    /**
     * ARTICLES PHOTOSETS
     */
    public getArticlePhotosets(articleId: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/article/${articleId}/articlephotoset`, this._httpOptions);
    }
    
    public getArticlePhotoset(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/articlephotoset/${id}`, this._httpOptions);
    }

    public createArticlePhotoset(data: Article): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/articlephotoset`, data, this._httpOptions);
    }

    public modifyArticlePhotoset(data: Article, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/articlephotoset/${id}`, data, this._httpOptions);
    }

    public deleteArticlePhotoset(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/articlephotoset/${id}`, this._httpOptions);
    }

    /**
     * ARTICLES VIEWS
     */
    public getArticleViews(articlePhotosetId: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/articlephotoset/${articlePhotosetId}/articleview`, this._httpOptions);
    }
    
    public getArticleView(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/articleview/${id}`, this._httpOptions);
    }

    public createArticleView(data: Article): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/articleview`, data, this._httpOptions);
    }

    public modifyArticleView(data: Article, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/articleview/${id}`, data, this._httpOptions);
    }

    public deleteArticleView(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/articleview/${id}`, this._httpOptions);
    }



    /**
     * MODELS & ARTICLES
     */

    public saveFade(data: any): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/savefade`, data, this._httpOptions);
    }

    /**
     * MODELS
     */
    public getUserModels(): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/user/model/`, this._httpOptions);
    }
    
    public getModel(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/model/${id}`, this._httpOptions);
    }

    public createModel(data: Model): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/model`, data, this._httpOptions);
    }

    public createModelFromZipFile(file: File): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/modelzip`, file, this._httpOptions);
    }

    public modifyModel(data: Model, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/model/${id}`, data, this._httpOptions);
    }

    public calibrateModel(data: any, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/model/${id}/calibrate`, data, this._httpOptions);
    }

    public correctModelPose(data: any, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/model/${id}/correct`, data, this._httpOptions);
    }

    public deleteModel(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/model/${id}`, this._httpOptions);
    }

    /**
     * MODELS PHOTOSETS
     */
    public getModelPhotosets(modelId: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/model/${modelId}/modelphotoset`, this._httpOptions);
    }
    
    public getModelPhotoset(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/modelphotoset/${id}`, this._httpOptions);
    }

    public createModelPhotoset(data: Model): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/modelphotoset`, data, this._httpOptions);
    }

    public modifyModelPhotoset(data: Model, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/modelphotoset/${id}`, data, this._httpOptions);
    }

    public deleteModelPhotoset(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/modelphotoset/${id}`, this._httpOptions);
    }

    /**
     * MODELS VIEWS
     */
    public getModelViews(modelPhotosetId: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/modelphotoset/${modelPhotosetId}/modelview`, this._httpOptions);
    }
    
    public getModelView(id: string): Observable<any> {
        return this._http.get<any>(`${this.urlBleuRefletBackend}/modelview/${id}`, this._httpOptions);
    }

    public createModelView(data: Model): Observable<any> {
        return this._http.post<any>(`${this.urlBleuRefletBackend}/modelview`, data, this._httpOptions);
    }

    public modifyModelView(data: Model, id: string): Observable<any> {
        return this._http.put<any>(`${this.urlBleuRefletBackend}/modelview/${id}`, data, this._httpOptions);
    }

    public deleteModelView(id: string): Observable<any> {
        return this._http.delete<any>(`${this.urlBleuRefletBackend}/modelview/${id}`, this._httpOptions);
    }


    public setOptions(): any {
        this._token = this._sessionService.getToken();
        if (this._token) {
            this._httpOptions = {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    Authorization: `Bearer ${this._token}`
                })
            };
        } else {
            this._httpOptions = {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    // 'Content-Type': 'application/json'
                })
            };
        }
    }
        
}
